import PropTypes from 'prop-types'
import {HomepageHeader} from './layout/HomepageHeader'
import {About} from './about/About'
import {Careers} from './careers/Careers'
import {PublicAuction} from './public_auction/PublicAuction'
// import { Statistics } from './statistics/Statistics.jsx'
import React, {Component} from 'react'
import {Products} from './products/Products'
import {Capabilities} from './capabilities/Capabilities'
import {EUProject} from './eu_projects/EUProject'
import {Contacts} from './contacts/Contacts.jsx'
import {SocialMedia} from './contacts/SocialMedia.jsx'
import {Footer} from './layout/Footer'
import ScrollableAnchor from 'react-scrollable-anchor'
import {configureAnchors} from 'react-scrollable-anchor'
import {
    Divider,
    Responsive,
    Segment,
    Visibility,
} from 'semantic-ui-react'

HomepageHeader.propTypes = {mobile: PropTypes.bool}

configureAnchors({offset: -45, scrollDuration: 530, behavior: 'smooth'})

class DesktopContainer extends Component {
    state = {}
    hideFixedMenu = () => this.setState({fixed: false})
    showFixedMenu = () => this.setState({fixed: true})

    render() {
        const {children} = this.props
        return (
            <Responsive {...Responsive.onlyComputer}>
                <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
                    <Segment textAlign='center' style={{minHeight: 700, padding: '1em 0em'}} vertical>
                        <HomepageHeader/>
                    </Segment>
                </Visibility>
                {children}
            </Responsive>
        )
    }
}

DesktopContainer.propTypes = {children: PropTypes.node}

class MobileContainer extends Component {
    state = {}

    render() {
        const {children} = this.props
        return (
            <Responsive {...Responsive.onlyMobile}>
                <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
                    <Segment textAlign='center' style={{minHeight: 600, padding: '1em 0em'}} vertical>
                        <HomepageHeader mobile/>
                    </Segment>
                </Visibility>
                {children}
            </Responsive>
        )
    }
}

MobileContainer.propTypes = {children: PropTypes.node}

class TabletContainer extends Component {
    state = {}

    render() {
        const {children} = this.props
        return (
            <Responsive {...Responsive.onlyTablet}>
                <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
                    <Segment textAlign='center' style={{minHeight: 600, padding: '1em 0em'}} vertical>
                        <HomepageHeader Desktop/>
                    </Segment>
                </Visibility>
                {children}
            </Responsive>
        )
    }
}

TabletContainer.propTypes = {children: PropTypes.node}

const ResponsiveContainer = ({children}) => (
    <div>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
        <TabletContainer>{children}</TabletContainer>
    </div>
)

ResponsiveContainer.propTypes = {children: PropTypes.node}

class HomepageLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showContacts: false}
    }

    handleContacts = () => {
        this.setState({showContacts: !this.state.showContacts})
    }

    render() {
        return (
            <ResponsiveContainer>
                <Divider/>
                <ScrollableAnchor id='about'>
                    <About/>
                </ScrollableAnchor>
                <Divider/>
                <ScrollableAnchor id='products'>
                    <Products/>
                </ScrollableAnchor>
                <Divider/>
                <ScrollableAnchor id='capabilities'>
                    <Capabilities/>
                </ScrollableAnchor>
                <ScrollableAnchor id='careers'>
                    <Careers/>
                </ScrollableAnchor>
                <Divider/>
                <ScrollableAnchor id='publicAuction'>
                    <PublicAuction/>
                </ScrollableAnchor>
                {/*<Divider/>*/}
                <ScrollableAnchor id='projectImg'>
                    <EUProject/>
                </ScrollableAnchor>
                {/*<Divider/>*/}
                <ScrollableAnchor id='socialMedia'>
                    <SocialMedia/>
                </ScrollableAnchor>
                <Divider/>
                <ScrollableAnchor id='contacts'>
                    {this.state.showContacts ? <Contacts/> : <div></div>}
                </ScrollableAnchor>
                <Footer showContacts={this.handleContacts}/>
            </ResponsiveContainer>
        )
    }
}

export default HomepageLayout