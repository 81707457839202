import React from 'react'
import {Divider, Grid, Image, Segment} from 'semantic-ui-react'
import EUProject1BigScreen from '../../img/EU1BigScreen.png'
import EUProject2BigScreen from '../../img/EU2BigScreen.png'
import EUProject1Mobile from '../../img/EU1Mobile1.png'
import EUProject2Mobile from '../../img/EU2Mobile1.png'

export const EUProject = () => {
    return (
        <Segment tyle={{overflowX: 'hidden'}}>
            < EUProjectComputerTablet className="ui computer only grid"/>
            < EUProjectComputerTablet className="ui tablet only grid"/>
            < EUProjectMobileComponent className="ui mobile only grid"/>
        </Segment>
    )
}

const EUProjectComputerTablet = props => {
    return (
        <Grid>
            <Grid.Row className={props.className} centered
                      style={{
                          padding: '1.5em 0em 1.5em 0em',
                          backgroundColor: '#004A8D',
                          margin: '6em 0em 0em 0em'
                      }}
                      columns={1}>
                <Description/>
            </Grid.Row>
            <Grid.Row centered className={props.className} style={{margin: '0.7em 0em -3em 0em'}} columns={1}>
                < EUProjectComponent src={EUProject2BigScreen}/>
            </Grid.Row>
            {
                // Dividers
                <Grid.Row centered verticalAlign='middle' className={props.className} columns={1}
                          style={{}}>
                    <Grid.Column centered verticalAlign='middle' className={props.className}
                                 style={{}}>

                        <Divider style={{
                            height: '1px',

                        }}/>
                        <Divider style={{
                            height: '1px',
                            margin: '4em 0em 4em 0em',

                        }}/>
                    </Grid.Column>
                </Grid.Row>
            }
            <Grid.Row centered className={props.className} style={{margin: '-4em 0em 1.5em 0em'}} columns={1}>
                < EUProjectComponent src={EUProject1BigScreen}/>
            </Grid.Row>
        </Grid>
    )
}

const EUProjectMobileComponent = props => {
    const className = props.className
    return (
        <Grid>
            <Grid.Row className={props.className} centered
                      style={{
                          padding: '1.5em 0em 1.5em 0em',
                          backgroundColor: '#004A8D',
                          margin: '6em 0em 0em 0em'
                      }}
                      columns={1}>
                <Description/>
            </Grid.Row>

            <Grid.Row className={className} style={{margin: '1em 0em -3em 0em'}} columns={1}>
                < EUProjectComponent src={EUProject2Mobile}/>
            </Grid.Row>
            {
                // Dividers
                <Grid.Row centered verticalAlign='middle' className={props.className} columns={1}
                          style={{}}>
                    <Grid.Column centered verticalAlign='middle' className={props.className}
                                 style={{}}>

                        <Divider style={{
                            height: '1px',

                        }}/>
                        <Divider style={{
                            height: '1px',
                            margin: '4em 0em 4em 0em',

                        }}/>
                    </Grid.Column>
                </Grid.Row>
            }
            <Grid.Row className={className} style={{margin: '-5em 0em 1em 0em'}} columns={1}>
                < EUProjectComponent src={EUProject1Mobile}/>
            </Grid.Row>
        </Grid>
    )
}

const EUProjectComponent = props => {
    const src = props.src
    return (
        <Grid.Column>
            <Image centered id=''
                   alt={`${src}`}
                   src={src}
            />
        </Grid.Column>
    )
}

const Description = () => {
    return (
        <p style={{fontSize: '2.0em', color: '#ffffff', fontWeight: 'bold'}}>
            EU Projects
        </p>
    )
}
